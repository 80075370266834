@import '~antd/lib/style/themes/default.less';

.wrapper {
	visibility: hidden;
	opacity: 0;
	z-index: 200;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(0, 0, 0, 0.2);
	overflow: hidden;
	transition: visibility 0s, opacity 0.3s;
}

.visible {
	visibility: visible;
	opacity: 1;
}

.container {
	overflow: hidden;
	position: absolute;
	top: 0;
	left: 0;
	background: white;
	width: 370px;
	max-width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	transform: translateX(-370px);
	transition: 0.3s;
}

.containerVisible {
	transform: translateX(0);
}

.close {
	font-size: 24px;
	color: black;
	position: relative;
	top: 1px;
}

.title {
	margin: 16px 24px;
}

.menu {
	border-top: 1px solid @grey-3;
	overflow-y: scroll;
	height: 100%;
	background-color: @grey-3;
	div {
		margin-top: 12px;
	}
}

.divider {
	width: 100%;
	height: 12px;
	background-color: @grey-3;
}

@primary-color: #1f6c04;@secondary-color: #268f01;@link-color: #1f6c04;@layout-body-background: #fff;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.911);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 2px;@border-color-base: #d9d9d9;@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
	0 9px 28px 8px rgba(0, 0, 0, 0.05);@grey-1: #ffffff;@grey-2: #fafafa;@grey-3: #f5f5f5;@grey-4: #f0f0f0;@grey-5: #d9d9d9;@grey-6: #bfbfbf;@grey-7: #8c8c8c;@grey-8: #595959;@grey-9: #262626;