@import '~antd/lib/style/themes/default.less';

.container {
	background-color: @grey-3;
	margin: 0px -100vw;
	padding: 0px 100vw;
	@media screen and (max-width: 767px) {
		background-color: @grey-1;
		// border: 1px solid @grey-5;
	}
	position: relative;
	z-index: 20;
}

.item {
	height: 64px;
	line-height: 64px;
}

.themenwelten {
	background-color: @primary-color;
}

.category {
	font-family: agenda, sans-serif;
	font-weight: 700;
	font-size: 18px;
	color: @primary-color;
	margin-left: 8px;
}

.categoryThemenwelten {
	font-family: agenda, sans-serif;
	font-weight: 600;
	font-size: 18px;
	color: white;
	margin: 0px 16px;
}

.searchbox {
	@media screen and (max-width: 767px) {
		div {
			width: 100%;
		}
	}
	@media screen and (min-width: 768px) {
		margin-right: 12px;
	}
}

.burger {
	width: 60px;
	height: 40px;
	border: 1px solid @grey-5;
	border-right: 0;
	border-radius: 0;
}

@primary-color: #1f6c04;@secondary-color: #268f01;@link-color: #1f6c04;@layout-body-background: #fff;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.911);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 2px;@border-color-base: #d9d9d9;@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
	0 9px 28px 8px rgba(0, 0, 0, 0.05);@grey-1: #ffffff;@grey-2: #fafafa;@grey-3: #f5f5f5;@grey-4: #f0f0f0;@grey-5: #d9d9d9;@grey-6: #bfbfbf;@grey-7: #8c8c8c;@grey-8: #595959;@grey-9: #262626;