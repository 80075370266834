.wrapper {
	position: relative;
	z-index: 10;
	transition: top 0.2s ease-in-out;
}

.container {
	position: absolute;
	box-shadow: 0px 24px 48px -48px rgba(0, 0, 0, 0.45);
	background-color: white;
}

.content {
	padding: 48px;
	height: 276px;
}

.image {
	background-color: #f5f5f5;
}

.imageOverlay {
	position: absolute;
	bottom: 14px;
	left: 32px;
	background-color: white;
	padding: 8px 12px;
}

.menu {
	height: 100%;
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
}

.sub {
	margin: 0px 64px 32px 0px;
	@media screen and (max-width: 991px) {
		margin: 0px 48px 24px 0px;
	}
}

.subsub {
	margin-top: 2px;
}

@primary-color: #1f6c04;@secondary-color: #268f01;@link-color: #1f6c04;@layout-body-background: #fff;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.911);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 2px;@border-color-base: #d9d9d9;@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
	0 9px 28px 8px rgba(0, 0, 0, 0.05);@grey-1: #ffffff;@grey-2: #fafafa;@grey-3: #f5f5f5;@grey-4: #f0f0f0;@grey-5: #d9d9d9;@grey-6: #bfbfbf;@grey-7: #8c8c8c;@grey-8: #595959;@grey-9: #262626;