.container {
	position: relative;
	height: 870px;
	@media screen and (max-width: 1167px) {
		height: 670px;
	}
	@media screen and (max-width: 767px) {
		height: 100vh;
	}
}

.image {
	position: absolute;
	left: calc((100vw - 1168px) / -2);
	width: calc(100vw + 48px);
	height: 100%;
	object-fit: cover;
	object-position: 100% 50%;
	@media screen and (max-width: 1216px) {
		left: -48px;
		object-position: 80% 50%;
	}
	@media screen and (max-width: 767px) {
		object-position: 50% 50%;
	}
}

.row {
	height: 100%;
}

.content {
	@media screen and (max-width: 767px) {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		height: 100%;
		padding-top: 48px;
	}
	text-align: center;
}

.title {
	font-family: agenda, sans-serif;
	font-size: 200px;
	line-height: 200px;
	font-weight: 600;
	color: white;
	@media screen and (max-width: 420px) {
		font-size: 147px;
	}
}

@primary-color: #1f6c04;@secondary-color: #268f01;@link-color: #1f6c04;@layout-body-background: #fff;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.911);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 2px;@border-color-base: #d9d9d9;@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
	0 9px 28px 8px rgba(0, 0, 0, 0.05);@grey-1: #ffffff;@grey-2: #fafafa;@grey-3: #f5f5f5;@grey-4: #f0f0f0;@grey-5: #d9d9d9;@grey-6: #bfbfbf;@grey-7: #8c8c8c;@grey-8: #595959;@grey-9: #262626;