@import '~antd/lib/style/themes/default.less';

.wrapper {
	position: fixed;
	width: 100vw;
	background-color: white;
	z-index: 20;
	top: 0;
	transition: top 0.2s ease-in-out;
	@media screen and (min-width: 768px) and (max-width: 1200px) {
		padding: 0px 24px;
	}
}

.container {
	max-width: 100%;
	width: 1168px;
	margin: auto;
	background-color: #fff;
}

.header {
	position: relative;
	z-index: 20;
	height: 64px;
	background-color: white;
}

.shippingInfo {
	position: relative;
	z-index: 20;
}

.logo {
	width: 150px;
	@media screen and (max-width: 768px) {
		padding: 8px 12px;
	}
}

.pages a {
	margin: 0px 32px 0px 12px !important;
}

.right {
	flex-wrap: nowrap;
}

.cartButton {
	margin-right: 12px;
	width: 64px;
	text-align: end;
}

.icon {
	color: @grey-8;
	font-size: 26px;
	margin-left: 8px;
}

.badge > sup {
	background: @primary-color;
}

.searchbox {
	margin-right: 8px;
}

.shippingIcon {
	margin: 8px 12px;
}

.closeBtn {
	color: #fff;
	font-size: 18px;
	position: absolute;
	right: 12px;
	cursor: pointer;
}

@primary-color: #1f6c04;@secondary-color: #268f01;@link-color: #1f6c04;@layout-body-background: #fff;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.911);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 2px;@border-color-base: #d9d9d9;@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
	0 9px 28px 8px rgba(0, 0, 0, 0.05);@grey-1: #ffffff;@grey-2: #fafafa;@grey-3: #f5f5f5;@grey-4: #f0f0f0;@grey-5: #d9d9d9;@grey-6: #bfbfbf;@grey-7: #8c8c8c;@grey-8: #595959;@grey-9: #262626;